// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import ImageList from '@material-ui/core/ImageList';
// import ImageListItem from '@material-ui/core/ImageListItem';
// import ImageListItemBar from '@material-ui/core/ImageListItemBar';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import image1 from '../images/image1.jpg';
// import image2 from '../images/image2.jpg';
// import image3 from '../images/image3.jpg';
// import image4 from '../images/image4.jpg';
// import image5 from '../images/image5.jpg';
// import image6 from '../images/image6.jpg';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'space-around',
//         overflow: 'hidden',
//         backgroundColor: theme.palette.background.paper,
//     },
//     imageList: {
//         flexWrap: 'nowrap',
//         // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
//         transform: 'translateZ(0)',
//     },
//     title: {
//         color: theme.palette.primary.light,
//     },
//     titleBar: {
//         background:
//             'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
//     },
// }));

// /**
//  * The example data is structured as follows:
//  *
//  * import image from 'path/to/image.jpg';
//  * [etc...]
//  *
//  * const itemData = [
//  *   {
//  *     img: image,
//  *     title: 'Image',
//  *     author: 'author',
//  *   },
//  *   {
//  *     [etc...]
//  *   },
//  * ];
//  */

// const itemData = [
//     {
//         img: image1,
//         title: 'Image',
//         author: 'author',
//     },
//     {
//         img: image2,
//         title: 'Image',
//         author: 'author',
//     },
//     {
//         img: image3,
//         title: 'Image',
//         author: 'author',
//     },
//     {
//         img: image4,
//         title: 'Image',
//         author: 'author',
//     },
//     {
//         img: image5,
//         title: 'Image',
//         author: 'author',
//     },
//     {
//         img: image6,
//         title: 'Image',
//         author: 'author',
//     },
// ];

// export default function SingleLineImageList() {
//     const classes = useStyles();

//     return (
//         <div className={classes.root}>
//             <ImageList className={classes.imageList} cols={2.5}>
//                 {itemData.map((item) => (
//                     <ImageListItem key={item.img}>
//                         <img src={item.img} alt={item.title} />
//                         <ImageListItemBar
//                             title={item.title}
//                             classes={{
//                                 root: classes.titleBar,
//                                 title: classes.title,
//                             }}
//                             actionIcon={
//                                 <IconButton aria-label={`star ${item.title}`}>
//                                     <StarBorderIcon className={classes.title} />
//                                 </IconButton>
//                             }
//                         />
//                     </ImageListItem>
//                 ))}
//             </ImageList>
//         </div>
//     );
// }


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
// import itemData from './itemData';
import image1 from '../images/image1.jpg';
import image2 from '../images/image2.jpg';
import image3 from '../images/image3.jpg';
import image4 from '../images/romakidslogo.jpeg';
import image5 from '../images/romakidslogo.jpeg';
import image6 from '../images/romakidslogo.jpeg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));


const itemData = [
    {
        img: image1,
        title: 'Image',
        author: 'author',
    },
    {
        img: image2,
        title: 'Image',
        author: 'author',
    },
    {
        img: image3,
        title: 'Image',
        author: 'author',
    },
    {
        img: image4,
        title: 'Image',
        author: 'author',
    },
    {
        img: image5,
        title: 'Image',
        author: 'author',
    },
    {
        img: image6,
        title: 'Image',
        author: 'author',
    },
];


export default function TitlebarImageList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight='auto' className={classes.imageList}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img src={item.img} alt={item.title} />
            <ImageListItemBar
              title={item.title}
              subtitle={<span>by: {item.author}</span>}
              actionIcon={
                <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
