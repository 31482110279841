import * as React from "react"
import AppBar from '../components/AppBar'
import "../styles/index.css"
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CardMedia from '../components/CardMedia'
import logo from '../images/romakidslogo.jpeg'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SimpleImage from '../components/SimpleImage'
import BasicFab from '../components/BasicFab'
import TipographyComponent from '../components/Typography'

import amor from '../images/amor.jpg';
import amistad from '../images/amistad.jpg';
import respeto from '../images/respeto.jpg';
import responsabilidad from '../images/responsabilidad.jpg';
import compromiso from '../images/compromiso.jpg';
import honestidad from '../images/honestidad.jpg';
import equidad from '../images/equidad.jpg';
import guarderia from '../images/guarderia.jpg';
import kinder from '../images/kinder.png';
import clubdetareas from '../images/clubdetareas.png';
import estanciavespertina from '../images/estanciavespertina.png';
import estimulaciontemprana from '../images/estimulaciontemprana.png';
import talleres from '../images/talleres.png';
import computacion from '../images/computacion.png';
import comedor from '../images/comedor.png';
import romakidslogo from '../images/romakidslogo.jpeg';
import FolderList from '../components/FolderList'

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// markup
const IndexPage = () => {

  React.useEffect(() => {

  })

  return (
    <>
      <AppBar />
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h1 style={{ fontSize: '30px', position: 'absolute', top: '200px', color: '#642F7A', zIndex: '101', fontWeight: 'bold' }} >Excelente Nivel Educativo</h1>
            <h1 style={{ fontSize: '27px', position: 'absolute', top: '250px', color: '#EF7F2D', zIndex: '101', fontWeight: 'bold' }} >Educación Personalizada</h1>
          </div>
          <section id="parallax" className="parallax">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="parallax-content p-4 shadow bg-white rounded text-center">

                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
          <br />
          {/* <section id="servicios" className="servicios" style={{ backgroundImage: 'linear-gradient(180deg, #ffffff, #FFF00F)' }}> */}
          <section id="servicios" className="servicios">
            <Box>
              <TipographyComponent texto="Servicios" variante="h4" align="center" color="secondary" />
              <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={guarderia} titulo="Guarderia" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={kinder} titulo="Kinder" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={clubdetareas} titulo="Club de Tareas" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={estanciavespertina} titulo="Estancia Vespertina" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={estimulaciontemprana} titulo="Estimulación Temprana" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={talleres} titulo="Talleres" subtitulo="Talleres divertidos para la formación integral de nuestr@s alumn@s" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={computacion} titulo="Computación" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={comedor} titulo="Comedor" subtitulo="Servicio de comedor con alimentos balanceados" texto="" />
                </Grid>
              </Grid>
            </Box>
          </section>
          <br />
          <br />
          <br />
          {/* <section id="nosotros" className="nosotros" style={{ backgroundImage: 'linear-gradient(180deg, #FFF00F, #EF7F2D)' }}> */}
          <section id="nosotros" className="nosotros">
            <Box>
              <TipographyComponent texto="Nosotros" variante="h4" align="center" color="secondary" />
              <Grid container justifyContent="center" spacing={2} style={{ textAlign: 'center' }}>
                <Grid item sm={12} md={12}>
                  <h1 style={{color:'#009ED6'}}>Kinder-Guarderia</h1>
                  <h2 style={{color:'#009ED6'}}>Horario 7:00-19:00 hrs.</h2>
                  <h3 style={{color:'#009ED6'}}>Roma Kids esta comprometido en ofrecer Excelente Nivel Educativo y Educación Personalizada a todos nuestr@s alumn@s</h3>
                  <h3 style={{color:'#E82583'}}>Agenda una visita a nuestro centro educativo. Nos encontramos muy cerca de ti.</h3>
                </Grid>
              </Grid>
            </Box>
          </section>
          <br />
          <br />
          <br />
          {/* <section id="ubicacion" className="ubicacion" style={{ backgroundImage: 'linear-gradient(180deg, #EF7F2D, #009ED6)' }}> */}
          <section id="ubicacion" className="ubicacion">
            <Box>
              <TipographyComponent texto="Contacto" variante="h4" align="center" color="secondary" />
              <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={12} md={6} style={{ textAlign: "center" }}>
                  <FolderList />
                </Grid>
                <Grid item sm={12} md={6} style={{ textAlign: "center" }}>
                  <Box elevation={3}>
                    <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.8832886942077!2d-99.17252038555482!3d19.417448086892936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff47adbcd8f5%3A0xb98a1c3dd7015147!2sColima%20390%2C%20Roma%20Nte.%2C%20Cuauht%C3%A9moc%2C%2006700%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1657635699362!5m2!1ses-419!2smx" width="360" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </section>
          <br />
          <br />
          <br />
          <section id="mision" className="mision" hidden="true">
            <Box>
              <TipographyComponent texto="Testimonios" variante="h4" align="center" color="secondary" />
              <Grid container justifyContent="center" spacing={2}>
                <Grid item md={3} sm={12}>
                  <CardMedia imagen={logo} />
                </Grid>
                <Grid item md={3} sm={12}>
                  <CardMedia imagen={logo} />
                </Grid>
                <Grid item md={3} sm={12}>
                  <CardMedia imagen={logo} />
                </Grid>
                <Grid item md={3} sm={12}>
                  <CardMedia imagen={logo} />
                </Grid>
              </Grid>
            </Box>
          </section>
          <br />
          <br />
          <br />
          {/* <section id="mision" className="mision" style={{ backgroundImage: 'linear-gradient(180deg, #E82583, #66307B)' }}> */}
          <section id="mision" className="mision">
            <Box>
              <TipographyComponent texto="Misión" variante="h4" align="center" color="secondary" />
              <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={6} md={12}>
                  <CardMedia titulo="Un espacio educativo responsable y de calidad, que contribuya a formar mejores seres humanos en su primera etapa educativa, a través del conocimiento y mediante la adquisición del propio aprendizaje, ayudando a fortalecer sus cimientos de manera integral aplicándolo en su actuar diario." subtitulo="" texto="" />
                </Grid>
              </Grid>
            </Box>
          </section>
          <br />
          <br />
          <br />

          {/* <section id="vision" className="vision" style={{ backgroundImage: 'linear-gradient(180deg, #66307B, #000)' }}> */}
          <section id="vision" className="vision">
            <Box>
              <TipographyComponent texto="Visión" variante="h4" align="center" color="secondary" />
              <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={6} md={12}>
                  <CardMedia titulo="Formar alumn@s integralmente competentes promoviendo el desarrollo intelectual, fortaleciendo la identidad personal y un apoyo para la construcción del conocimiento basado en el triangulo interactivo: Alumn@s, padres y maestr@s, este ultimo como guiá y orientador de la actividad mental constructivista del niñ@." subtitulo="" texto="" />
                </Grid>
              </Grid>
            </Box>
          </section>
          <br />
          <br />
          <br />
          {/* <section id="valores" className="valores" style={{ backgroundImage: 'linear-gradient(180deg, #000, #000)' }}> */}
          <section id="valores" className="valores">
            <Box>
              <TipographyComponent texto="Valores" variante="h4" align="center" color="secondary" />
              <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={respeto} titulo="Respeto" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={amor} titulo="Amor" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={amistad} titulo="Amistad" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={responsabilidad} titulo="Responsabilidad" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={compromiso} titulo="Compromiso" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={honestidad} titulo="Honestidad" subtitulo="" texto="" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <CardMedia imagen={equidad} titulo="Equidad" subtitulo="" texto="" />
                </Grid>
              </Grid>
            </Box>
          </section>


        </Container>
      </React.Fragment>

      <a href="https://wa.me/525517313307?text=Buen día necesito informes sobre ..." class="whatsapp" target="_blank"> <WhatsAppIcon style={{ fontSize: '50px' }} /></a>

      <br />
      <section id="footer" className="footer">
        <Box>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item sm={12} md={12}>
              Copyright©2010, All Right Reserved Roma kids
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}

export default IndexPage


// 
// 
// 
// 
// 
