import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logo from '../images/romakidslogo.jpeg'
import { Link } from "gatsby"
const pages = ['servicios', 'nosotros', 'contacto', 'mision', 'vision', 'blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="fixed" color="inherit">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={logo} alt="" height={100} />
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >

                            <MenuItem key={1}>
                                <Button
                                    key={'servicios'}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#servicios">Servicios</Link>
                                </Button>
                            </MenuItem>
                            <MenuItem key={2}>
                                <Button
                                    key={'nosotros'}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#nosotros">Nosotros</Link>
                                </Button>
                            </MenuItem>
                            <MenuItem key={2}>
                                <Button
                                    key={'contacto'}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#contacto">Contacto</Link>
                                </Button>
                            </MenuItem>
                            <MenuItem key={2}>
                                <Button
                                    key={'mision'}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#mision">Misión</Link>
                                </Button>
                            </MenuItem>
                            <MenuItem key={2}>
                                <Button
                                    key={'vision'}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#vision">Visión</Link>
                                </Button>
                            </MenuItem>
                            <MenuItem key={2}>
                                <Button
                                    key={'blog'}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="/blog">Blog</Link>
                                </Button>
                            </MenuItem>

                        </Menu>
                    </Box>
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {/* {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >                                
                                <Link style={{fontWeight:'bold',color:'#E12281',textDecoration: 'none'}} to={page==='blog'?'/blog':`#${page}`}>{page}</Link>
                            </Button>
                        ))} */}
                        <Button
                            key={'servicios'}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#servicios">Servicios</Link>
                        </Button>
                        <Button
                            key={'nosotros'}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#nosotros">Nosotros</Link>
                        </Button>
                        <Button
                            key={'contacto'}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#contacto">Contacto</Link>
                        </Button>
                        <Button
                            key={'mision'}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#mision">Misión</Link>
                        </Button>
                        <Button
                            key={'vision'}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="#vision">Visión</Link>
                        </Button>
                        <Button
                            key={'blog'}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link style={{ fontWeight: 'bold', color: '#E12281', textDecoration: 'none' }} to="/blog">Blog</Link>
                        </Button>
                    </Box>

                    {/* <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default ResponsiveAppBar;
