import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

export default function MediaControlCard({ imagen, titulo, subtitulo, texto }) {
    const theme = useTheme();

    return (
        <Card sx={{ display: 'flex' }}>
            {imagen &&
                <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={imagen}
                    alt=""
                />
            }
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" align="justify">
                        {titulo}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {subtitulo}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    {texto}
                </Box>
            </Box>
        </Card>
    );
}
